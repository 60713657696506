import React from "react";
import { Helmet } from "react-helmet";
// import aboutImg from "../assets/img/aboutbanner.webp";
import Clients from "../components/Clients2";
import "../styles/About.css";
import Social from "../components/Social";
// import ServiceTest from "../assets/img/service-test.png";
import WWC1 from "../assets/img/wcc-1.png";
import WWC2 from "../assets/img/wcc-2.png";
import WWC3 from "../assets/img/wcc-3.png";
import WWC4 from "../assets/img/wcc-4.png";

const About = () => {
  return (
    <div className="about-main">
      <Helmet>
        <title>Colorkaar | About</title>
        <meta
          name="description"
          content="Colorkaar - Color Grading Boutique Commercials , Films and Webseries Color Grading Services"
        />
      </Helmet>
      <div className="about-main-desktop">
        <div className="about-about">
          <div className="about-about-body">
            <h3>WE ARE COLORKAAR</h3>
            <p className="about-about-description">
              {/* I am <span style={{ textDecoration: "none" }}>Uday Oswal</span> ,
              a freelance colorist with over{" "}
              <span style={{ textDecoration: "none" }}>
                8 years of experience
              </span>{" "}
              in image making and image processing. I run my own grading
              boutique in Chandigarh, India. In last two years I have graded
              over <span style={{ textDecoration: "none" }}>200+ projects</span>{" "}
              from clients across the world remotely and had absolute pleasure
              to work with brands like{" "}
              <span style={{ textDecoration: "none" }}>
                Amazon, KFC, Hyundai, Nikon, Panasonic, Burger King
              </span>{" "}
              among many more.
              <br />
              <br />
              My professional credo has always been to avoid being restricted to
              a particular color genre, a straightforward cumulative approach on
              it for all jobs, or a particular grading methodology. Each
              endeavor needs its own special amount of time, understanding
              effort, mood, and language. */}
              {/* <span style={{ fontWeight: 600 }}>Flawless Detail:</span> In our
              creative process, perfection is not just a goal but an obsession,
              meticulously addressed frame by frame.
              <br></br>
              <br></br>
              <span style={{ fontWeight: 600 }}>Global Perspective:</span> Our
              craft has been refined through collaboration with a diverse array
              of clients worldwide, giving us a unique and broad global
              perspective.
              <br></br>
              <br></br>
              <span style={{ fontWeight: 600 }}>
                Vibrant Storytelling:
              </span>{" "}
              From heart-wrenching dramas to captivating commercials, we
              specialize in telling your story with a vivid and dynamic use of
              color.
              <br></br>
              <br></br>
              <span style={{ fontWeight: 600 }}>
                Effortless Collaboration:
              </span>{" "}
              Our approach involves active listening, continuous refinement, and
              a commitment to bringing your unique vision to life with ease and
              effectiveness.
              <br></br>
              <br></br>
              <span style={{ fontWeight: 600 }}>Technical Mastery:</span>{" "}
              Equipped with cutting-edge tools, we achieve technical mastery
              that consistently yields stunning results, adaptable to any
              platform or medium.
              <br></br>
              <br></br>
              <span style={{ fontWeight: 600 }}>Remote Made Easy:</span>{" "}
              Experience seamless collaboration with us, where remote work is
              not only feasible but optimized for flawless delivery, overcoming
              any distance-related challenges.
              <br></br>
              <br></br>
              <span style={{ fontWeight: 600 }}>Fast Turnarounds:</span> Meeting
              deadlines is our priority, and we accomplish this without
              compromising the exceptional quality that defines our work. Your
              project is completed promptly and impeccably.{" "} */}
              Not just, your average color grading studio. We're a passionate
              collective of colorists and finishing artists based in Mumbai,
              India, fueled by a passion for image post processing. From our
              cozy grading boutique, we've embarked on a global journey and
              we've delivered world-class color grading to over 350+ projects
              for a diverse clientele spanning the globe.
            </p>
          </div>
          <img
            src="https://colorkaar.com/aboutbanner.webp"
            alt="colorkaar-profile-studio-setup"
            className="about-about-img"
            loading=""
          />
        </div>
        {/* <div class="accordion">
          <div class="accordion-item">
            <input type="checkbox" id="accordion1" />
            <label for="accordion1" class="accordion-item-title">
              <span class="icon"></span>Flawless Detail
            </label>
            <div class="accordion-item-desc">
              In our creative process, perfection is not just a goal but an
              obsession, meticulously addressed frame by frame.
            </div>
          </div>
          <div class="accordion-item">
            <input type="checkbox" id="accordion2" />
            <label for="accordion2" class="accordion-item-title">
              <span class="icon"></span>Global Perspective
            </label>
            <div class="accordion-item-desc">
              Our craft has been refined through collaboration with a diverse
              array of clients worldwide, giving us a unique and broad global
              perspective.
            </div>
          </div>
          <div class="accordion-item">
            <input type="checkbox" id="accordion3" />
            <label for="accordion3" class="accordion-item-title">
              <span class="icon"></span>Vibrant Storytelling
            </label>
            <div class="accordion-item-desc">
              From heart-wrenching dramas to captivating commercials, we
              specialize in telling your story with a vivid and dynamic use of
              color.
            </div>
          </div>
          <div class="accordion-item">
            <input type="checkbox" id="accordion4" />
            <label for="accordion4" class="accordion-item-title">
              <span class="icon"></span>Effortless Collaboration
            </label>
            <div class="accordion-item-desc">
              Our approach involves active listening, continuous refinement, and
              a commitment to bringing your unique vision to life with ease and
              effectiveness.
            </div>
          </div>
          <div class="accordion-item">
            <input type="checkbox" id="accordion5" />
            <label for="accordion5" class="accordion-item-title">
              <span class="icon"></span>Technical Mastery
            </label>
            <div class="accordion-item-desc">
              Equipped with cutting-edge tools, we achieve technical mastery
              that consistently yields stunning results, adaptable to any
              platform or medium.
            </div>
          </div>
          <div class="accordion-item">
            <input type="checkbox" id="accordion6" />
            <label for="accordion6" class="accordion-item-title">
              <span class="icon"></span>Remote Made Easy
            </label>
            <div class="accordion-item-desc">
              Experience seamless collaboration with us, where remote work is
              not only feasible but optimized for flawless delivery, overcoming
              any distance-related challenges.
            </div>
          </div>{" "}
          <div class="accordion-item">
            <input type="checkbox" id="accordion7" />
            <label for="accordion7" class="accordion-item-title">
              <span class="icon"></span>Fast Turnarounds
            </label>
            <div class="accordion-item-desc">
              Meeting deadlines is our priority, and we accomplish this without
              compromising the exceptional quality that defines our work. Your
              project is completed promptly and impeccably.
            </div>
          </div>
        </div> */}
        {/* <div className="about-main-choose-colorkaar">
          <p className="about-about-description">
            <span style={{ fontWeight: 600 }}>Flawless Detail:</span> In our
            creative process, perfection is not just a goal but an obsession,
            meticulously addressed frame by frame.
            <br></br>
            <br></br>
            <span style={{ fontWeight: 600 }}>Global Perspective:</span> Our
            craft has been refined through collaboration with a diverse array of
            clients worldwide, giving us a unique and broad global perspective.
            <br></br>
            <br></br>
            <span style={{ fontWeight: 600 }}>Vibrant Storytelling:</span> From
            heart-wrenching dramas to captivating commercials, we specialize in
            telling your story with a vivid and dynamic use of color.
            <br></br>
            <br></br>
            <span style={{ fontWeight: 600 }}>
              Effortless Collaboration:
            </span>{" "}
            Our approach involves active listening, continuous refinement, and a
            commitment to bringing your unique vision to life with ease and
            effectiveness.
            <br></br>
            <br></br>
            <span style={{ fontWeight: 600 }}>Technical Mastery:</span> Equipped
            with cutting-edge tools, we achieve technical mastery that
            consistently yields stunning results, adaptable to any platform or
            medium.
            <br></br>
            <br></br>
            <span style={{ fontWeight: 600 }}>Remote Made Easy:</span>{" "}
            Experience seamless collaboration with us, where remote work is not
            only feasible but optimized for flawless delivery, overcoming any
            distance-related challenges.
            <br></br>
            <br></br>
            <span style={{ fontWeight: 600 }}>Fast Turnarounds:</span> Meeting
            deadlines is our priority, and we accomplish this without
            compromising the exceptional quality that defines our work. Your
            project is completed promptly and impeccably.{" "}
          </p>
        </div> */}

        <div className="about-main-desktop-contact">
          Beyond  <span style={{ color: "#D59DFF", fontWeight: "600" }}>c</span>
          <span style={{ color: "#7979FF", fontWeight: "600" }}>o</span>
          <span style={{ color: "#9EFF9E", fontWeight: "600" }}>l</span>
          <span style={{ color: "#FFFD8D", fontWeight: "600" }}>o</span>
          <span style={{ color: "#FA4648", fontWeight: "600" }}>r</span> : What Sets Colorkaar Apart?
        </div>

        <div className="about-services">
          {[
            {
              imgAlt: "colorkaar-focus",
              imgURL: WWC1,
              title: "Technical Mastery",
              description:
                "Cutting-edge tools, stunning results, on any platform",
            },
            {
              imgAlt: "colorkaar-focus",
              imgURL: WWC2,
              title: "Fast Turnarounds",
              description:
                "We meet your deadlines without compromising quality",
            },
            {
              imgAlt: "colorkaar-focus",
              imgURL: WWC3,
              title: "Effortless Collaboration",
              description: "We listen, refine and bring your vision to life",
            },
            {
              imgAlt: "colorkaar-focus",
              imgURL: WWC4,
              title: "Remote Made Easy",
              description: "Seamless workflow, no matter the distance",
            },
          ].map((m, i) => (
            <div key={i} className="about-services-card-main">
              <div className="about-services-card">
                <img src={m.imgURL} alt={m.imgAlt} />
              </div>
              <span style={{ fontWeight: 600 }}>{m.title}</span>

              <p className="about-services-p">{m.description}</p>
            </div>
          ))}
        </div>
        {/* <div className="about-main-desktop-contact">Our Services</div>
        <div className="carousel-div">
          <Carousel
            perPage={1}
            perPage640={1}
            autoplay={true}
            interval={2500}
            image={[
              {
                imgAlt: "colorkaar-focus",
                imgURL: Service1,
                title: "Film & TV color grading",
                description:
                  "Feature films, documentaries, short films, TV shows, and more",
              },
              {
                imgAlt: "colorkaar-focus",
                imgURL: Service2,
                title: "Commercial & music video color grading",
                description:
                  "Eye-catching visuals for your brand or music project",
              },
              {
                imgAlt: "colorkaar-focus",
                imgURL: Service3,
                title: "Digital cinema mastering",
                description:
                  "Prepare your film for theatrical release or streaming platforms",
              },
              {
                imgAlt: "colorkaar-focus",
                imgURL: Service4,
                title: "LUT creation",
                description:
                  "Custom LUTs for a consistent look across your projects",
              },
            ]}
            classes=""
          />
        </div> */}
        <div className="about-main-desktop-contact">SOME OF OUR CLIENTS</div>

        <Clients />
        <div className="about-main-desktop-storyteller">
          We are storytellers who narrate with{" "}
          <span style={{ color: "#D59DFF", fontWeight: "600" }}>c</span>
          <span style={{ color: "#7979FF", fontWeight: "600" }}>o</span>
          <span style={{ color: "#9EFF9E", fontWeight: "600" }}>l</span>
          <span style={{ color: "#FFFD8D", fontWeight: "600" }}>o</span>
          <span style={{ color: "#FA4648", fontWeight: "600" }}>r</span>. Need
          help with this narration? Contact us today!
          {/* <div className="about-main-desktop-contact">Contact us today!</div> */}
        </div>
        <Social
          linkedin={false}
          instagram={true}
          whatapp={true}
          youtube={false}
          twitter={false}
          email={true}
        />

       
      </div>
    </div>
  );
};

export default About;
