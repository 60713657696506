import React from "react";
import { useMediaQuery } from "../hooks/useInlineMediaQuery";
// import ClientSection from '../assets/img/clientSection2.png'
// import ClientSectionWithoutHeader from "../assets/img/clientSection3.png";

import "../styles/Client.css";

const clientArr = [
  {
    src: "/images/audi-colorkaar.png",
    alt: "colorkaar-clients",
    padding: "22px",
  },
  {
    src: "/images/kfc-colorkaar.png",
    alt: "colorkaar-clients",
    padding: "22px",
  },
  {
    src: "/images/polaris-colorkaar.png",
    alt: "colorkaar-clients",
    padding: "12px",
  },
  {
    src: "/images/nikon-colorkaar.png",
    alt: "colorkaar-clients",
    padding: "22px",
  },
  {
    src: "/images/amd-colorkaar.png",
    alt: "colorkaar-clients",
    padding: "22px",
  },
  {
    src: "/images/amazon-colorkaar.png",
    alt: "colorkaar-clients",
    padding: "22px",
  },
  {
    src: "/images/hyundai-colorkaar.png",
    alt: "colorkaar-clients",
    padding: "22px",
  },
  {
    src: "/images/starbucks-colorkaar.jpeg",
    alt: "colorkaar-clients",
    padding: "22px",
  },
  {
    src: "/images/panasonic-colorkaar.png",
    alt: "colorkaar-clients",
    padding: "12px",
  },
  {
    src: "/images/vistara-colorkaar.png",
    alt: "colorkaar-clients",
    padding: "18px",
  },
  {
    src: "/images/lensekart-colorkaar.png",
    alt: "colorkaar-clients",
    padding: "26px",
  },
  {
    src: "/images/ather-colorkaar.jpeg",
    alt: "colorkaar-clients",
    padding: "12px",
  },

  {
    src: "/images/mango-colorkaar.png",
    alt: "colorkaar-clients",
    padding: "12px",
  },
  {
    src: "/images/estee-colorkaar.png",
    alt: "colorkaar-clients",
    padding: "22px",
  },
  {
    src: "/images/jimmys-colorkaar.png",
    alt: "colorkaar-clients",
    padding: "12px",
  },
  {
    src: "/images/swiggy-colorkaar.jpeg",
    alt: "colorkaar-clients",
    padding: "26px",
  },
  {
    src: "/images/nautica-colorkaar.png",
    alt: "colorkaar-clients",
    padding: "12px",
  },

  {
    src: "/images/burgerking-colorkaar.png",
    alt: "colorkaar-clients",
    padding: "26px",
  },
  {
    src: "/images/aws-colorkaar.png",
    alt: "colorkaar-clients",
    padding: "22px",
  },

  {
    src: "/images/crocs-colorkaar.jpeg",
    alt: "colorkaar-clients",
    padding: "26px",
  },

 
  {
    src: "/images/japurrugs-colorkaar.jpg",
    alt: "colorkaar-clients",
    padding: "12px",
  },
  {
    src: "/images/mattel-colorkaar.png",
    alt: "colorkaar-clients",
    padding: "26px",
  },


  {
    src: "/images/sandisk-colorkaar.png",
    alt: "colorkaar-clients",
    padding: "12px",
  },
  {
    src: "/images/tatamotor-colorkaar.jpeg",
    alt: "colorkaar-clients",
    padding: "22px",
  },
  // {
  //   src: "/images/zydus-colorkaar.png",
  //   alt: "colorkaar-clients",
  //   padding: "22px",
  // },
];
const Clients2 = () => {
  const isRowBased = useMediaQuery("(max-width: 998px)");

  return (
    <React.Fragment>
      {" "}
      <div className="client2-main-div">
        <div className="client2-main">
          {/* <img src={ClientSectionWithoutHeader} alt="colorkaar-clients" /> */}
          {clientArr.length > 0 &&
            clientArr.map((m, i) => (
              <img
                key={"client1" + i}
                src={m.src}
                alt={m.alt}
                style={{
                  padding: isRowBased ? `calc(${m.padding} - 8px)` : m.padding,
                }}
              />
            ))}
        </div>
      </div>
      {/* <div class="client-slide-track">
        {clientArr.length > 0 &&
          clientArr.map((m, i) => (
            <div class="client-slide scrollLeft">
              <img
                key={"client2" + i}
                src={m.src}
                alt={m.alt}
                style={{ padding: m.padding }}
              />
            </div>
          ))}
      </div>
      <div class="client-slide-track">
        {clientArr.length > 0 &&
          clientArr.map((m, i) => (
            <div class="client-slide scrollRight">
              <img
                key={"client3" + i}
                src={m.src}
                alt={m.alt}
                style={{ padding: m.padding }}
              />
            </div>
          ))}
      </div> */}
    </React.Fragment>
  );
};

export default Clients2;
