import { NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import "../styles/Navbar.css";

// Images
import Logo from "../assets/img/ColorkaarLogo2.webp";

// Icons
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose, AiOutlineMail } from "react-icons/ai";

//Google Analytics
import { useAnalyticsEventTracker } from "../hooks/useAnalyticsEventTracker";
import { trackPathForAnalytics } from "../hooks/useAnalyticsEventTracker";

const Navbar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker("Navigation");
  const { pathname, search } = useLocation();

  useEffect(() => {
    trackPathForAnalytics(pathname, search, pathname.split("/")[1]);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <nav className="nav-main">
        <div className={showSidebar ? "nav-mobile-logo nav-logo" : "nav-logo"}>
          <NavLink to="/" onClick={() => gaEventTracker("home")}>
            <img src={Logo} alt="colorkaar-logo" loading="" />
          </NavLink>
        </div>

        <div className={"nav-menu-links"}>
          <ul>
            <li>
              <NavLink to="/" onClick={() => gaEventTracker("home")}>
                HOME
              </NavLink>
              <NavLink to="/about" onClick={() => gaEventTracker("about")}>
                ABOUT
              </NavLink>
              <NavLink to="/work" onClick={() => gaEventTracker("work")}>
                WORK
              </NavLink>
              <NavLink to="/reel" onClick={() => gaEventTracker("reel")}>
                REEL
              </NavLink>
              <NavLink to="/contact" onClick={() => gaEventTracker("contact")}>
                CONTACT
              </NavLink>
            </li>
          </ul>
        </div>
        {showSidebar && (
          <div className={"nav-mobile-menu-links"}>
            <ul>
              <li>
                <NavLink
                  to="/"
                  onClick={() => {
                    setShowSidebar(!showSidebar);
                    gaEventTracker("home");
                  }}
                >
                  HOME
                </NavLink>
                <NavLink
                  to="/about"
                  onClick={() => {
                    setShowSidebar(!showSidebar);
                    gaEventTracker("about");
                  }}
                >
                  ABOUT
                </NavLink>
                <NavLink
                  to="/work"
                  onClick={() => {
                    setShowSidebar(!showSidebar);
                    gaEventTracker("work");
                  }}
                >
                  WORK
                </NavLink>
                <NavLink
                  to="/reel"
                  onClick={() => {
                    setShowSidebar(!showSidebar);
                    gaEventTracker("reel");
                  }}
                >
                  REEL
                </NavLink>
                <NavLink
                  to="/contact"
                  onClick={() => {
                    setShowSidebar(!showSidebar);
                    gaEventTracker("contact");
                  }}
                >
                  CONTACT
                </NavLink>
              </li>
            </ul>
          </div>
        )}

        <div
          className={showSidebar ? "nav-mobile-socialmedia" : "nav-socialmedia"}
        >
          <ul>
            <li>
              <a
                href="mailto:contact@colorkaar.com"
                target="_blank"
                rel="noreferrer"
              >
                <AiOutlineMail onClick={() => gaEventTracker("mail")} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/colorkaar/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram onClick={() => gaEventTracker("instagram")} />
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/+918872400624"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp onClick={() => gaEventTracker("whatsapp")} />
              </a>
            </li>
          </ul>
        </div>
        <div className="nav-hamburgermenu">
          {!showSidebar && (
            <GiHamburgerMenu onClick={() => setShowSidebar(!showSidebar)} />
          )}
          {showSidebar && (
            <AiOutlineClose
              onClick={() => setShowSidebar(!showSidebar)}
              color="var(--bg)"
            />
          )}
        </div>
      </nav>
      {showSidebar && <div className="backdrop"></div>}
    </>
  );
};

export default Navbar;
