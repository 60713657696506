import React from "react";
import "../styles/Heading.css";

export const Heading = ({ heading, color }) => {
  return (
    <div className="heading-main" style={{ color }}>
      {heading}
    </div>
  );
};

export default Heading;
