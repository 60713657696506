import React, { useEffect, useState } from "react";
import "../styles/List.css";

//Icons
import { IoTrashBinOutline, IoLink } from "react-icons/io5";
import { TiTick } from "react-icons/ti";
import { BiPhotoAlbum } from "react-icons/bi";
import { AiOutlineEdit } from "react-icons/ai";
import { GrDrag } from "react-icons/gr";

import { projectFirestore, projectStorage } from "../firebase/config";

const List = ({
  position,
  name,
  link,
  homepage,
  workpage,
  multiplestring,
  coverPhoto,
  id,
  imageSize,
  handleEdit,
}) => {
  const [multiplePhotoCount, setMultiplePhotoCount] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    if (
      multiplestring === "" ||
      multiplestring === null ||
      multiplestring === undefined ||
      multiplestring === false
    ) {
      //some error
    } else {
      let getArr = multiplestring.split(",");
      setMultiplePhotoCount(getArr.length);
    }
  }, [multiplestring]);

  async function firebaseDelete(photoURL) {
    const storageRef = projectStorage.ref();
    const coverArr = photoURL.split("/");
    const coverArrsecondsplit = coverArr[7].split("?");
    const fileRef = storageRef.child(coverArrsecondsplit[0]);
    
    await fileRef
      .delete()
      .then((res) => {
        return true;
      })
      .catch((err) => {
        return false;
      });
  }

  const handleDelete = () => {
    if (
      multiplestring === "" ||
      multiplestring === null ||
      multiplestring === undefined ||
      multiplestring === false
    ) {
      //error
    } else {
      let boolcoverphoto = firebaseDelete(coverPhoto);

      const multistringArr = multiplestring.split(",");
      let boolmultiplephoto = multistringArr.map((msa) => firebaseDelete(msa));

      if (!boolcoverphoto || !boolmultiplephoto) {
        setError("List cannot be deleted");
      } else {
        setError("");
        projectFirestore.collection("images").doc(id).delete();
      }
    }
  };
  return (
    <>
      {error && <span className="list-error">{error}</span>}
      <div className="list-main">
        <GrDrag style={{marginRight:"6px"}}/>
        <img src={coverPhoto} alt={name} loading="" />
        <div className="list-column">
          <span>
            {+position+1}. {name}
          </span>

          <div className="list-row">
            {imageSize === "fit" && <span>S</span>}
            {imageSize === "horizontal" && <span>M</span>}
            {imageSize === "big" && <span>L</span>}
            <a href={link} target="_blank" rel="noreferrer">
              Link <IoLink />
            </a>
            {homepage && (
              <span>
                H <TiTick />
              </span>
            )}
            {workpage && (
              <span>
                W <TiTick />
              </span>
            )}
            <span>
              {multiplePhotoCount} <BiPhotoAlbum />
            </span>
            <button type="button" onClick={() => handleEdit(id)}>
              <AiOutlineEdit />
            </button>
            <button type="button" onClick={handleDelete}>
              <IoTrashBinOutline />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default List;
