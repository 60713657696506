import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import VideoSection from "./VideoSection";
import "../styles/RandomPhotoGrid.css";

const RandomPhotoGrid = ({ docs, catergory, page }) => {
  const [open, setOpen] = useState([]);
  const [size, setsize] = useState([]);
  const [modalToggle, setModalToggle] = useState(false);
  const [modalValue, setModalValue] = useState([]);
  function HoverdBox(key) {
    setOpen({ [key]: true });
  }
  function HoveredBoxLeave(key) {
    setOpen({ [key]: false });
  }

  useEffect(() => {
    // let sizeClassNames = ["fit","horizontal"];
    for (let i = 0; i < docs.length; i++) {
      // let random = Math.floor(Math.random() * sizeClassNames.length );
      setOpen([false]);
      // if(i === docs.length-1){

      //   setsize(pvs => [...pvs,"big"]);
      // }else{

      setsize((pvs) => [...pvs, docs[i].imageSize]);

      // }
    }
  }, [docs]);

  const handleModalToggle = (doc) => {
    setModalToggle(true);
    setModalValue(doc);
  };
  const closeModalToggle = (e) => {
    e.preventDefault();
    setModalToggle(false);
    setModalValue([]);
  };
  return (
    <React.Fragment>
      <Modal
        modalValue={modalValue}
        show={modalToggle}
        closeModalToggle={closeModalToggle}
      >
        {" "}
        <VideoSection
          height={"60vh"}
          width={"90vw"}
          mheight={"60vh"}
          mwidth={"90vw"}
          videoLink={modalValue?.videoLink}
          name={modalValue?.name}
        />
        {/* <Heading heading={modalValue?.name} color={"var(--bg)"} /> */}
      </Modal>
      <div className="container">
        {catergory?.name?.toLowerCase() !== "all" && page === "Work"
          ? docs.map(
              (doc, k) =>
                (doc.id === "DeEZIeulpqD7UghQIKIY" ||
                  (doc?.category !== undefined &&
                    doc?.category.find((f) => f.name === catergory?.name))) && (
                  <>
                    <div
                      key={k}
                      onClick={() => handleModalToggle(doc)}
                      onMouseOver={() => HoverdBox(k)}
                      onMouseOut={() => HoveredBoxLeave(k)}
                      className={size[k]}
                    >
                      <img src={doc.coverPhoto} alt={doc.name} loading="" />

                      {open && ""}
                      {open[k] && (
                        <div className="photo-grid-span">
                          <span>
                            <p>{doc.name}</p>
                          </span>
                        </div>
                      )}
                    </div>
                  </>
                )
            )
          : docs.map((doc, k) => (
              <div
                key={k}
                onClick={() => handleModalToggle(doc)}
                onMouseOver={() => HoverdBox(k)}
                onMouseOut={() => HoveredBoxLeave(k)}
                className={size[k]}
              >
                <img src={doc.coverPhoto} alt={doc.name} loading="" />

                {open && ""}
                {open[k] && (
                  <div className="photo-grid-span">
                    <span>
                      <p>{doc.name}</p>
                    </span>
                  </div>
                )}
              </div>
            ))}
      </div>
      {/* <div className="container">
        {docs.map((doc, k) => (
          <Link
            key={k}
            to={"/work/" + doc.id}
            onMouseOver={() => HoverdBox(k)}
            onMouseOut={() => HoveredBoxLeave(k)}
            className={size[k]}
          >
            <img src={doc.coverPhoto} alt={doc.name} loading="" />

            {open && ""}
            {open[k] && (
              <div className="photo-grid-span">
                <span>
                  <p>{doc.name}</p>
                </span>
              </div>
            )}
          </Link>
        ))}
      </div> */}
    </React.Fragment>
  );
};

export default RandomPhotoGrid;
