import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
//import { getAnalytics } from "https://www.gstatic.com/firebasejs/9.9.1/firebase-analytics.js";

const firebaseConfig = {
    apiKey: "AIzaSyCwgTGNlbiVZTmQfRhbiCFQUBwRL2t9waY",
    authDomain: "colorkaar-2ddd8.firebaseapp.com",
    projectId: "colorkaar-2ddd8",
    storageBucket: "colorkaar-2ddd8.appspot.com",
    messagingSenderId: "953744716738",
    appId: "1:953744716738:web:d5d3ca262949baf8fa1c5b",
    measurementId: "G-ZL03B7S071"
  };

 
  firebase.initializeApp(firebaseConfig);
//   const analytics = getAnalytics(app);

  const projectStorage = firebase.storage();
  const projectFirestore = firebase.firestore();
  const auth = firebase.auth();
  const timestamp = firebase.firestore.FieldValue.serverTimestamp;

  export {projectStorage,projectFirestore,timestamp,auth};

