import React from "react";
import "../styles/Dots.css";

export const Dots = () => {
  return (
    <div className="dots-main">
      <ul className="dropbtn dots-icons btn-right showLeft">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};
