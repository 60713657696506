import React from 'react';
import '../styles/DownArrow.css';

const DownArrow = () => {
  return (
    <div className="down-arrow"></div>
  )
}

export default DownArrow;
