import React from "react";
import { ImCross } from "react-icons/im";
import "../styles/Modal.css";

const Modal = (props) => {
  return (
    <React.Fragment>
      <div
        className="modal-backdrop"
        style={{
          display: props.show ? "flex" : "none",
        }}
      ></div>
      <div
        className="modal-main"
        style={{
          transform: props.show
            ? "translate(-50%,-50%)"
            : "translate(-50%,-100%)",
          display: props.show ? "block" : "none",
        }}
      >
        <p
          style={{ float: "right", cursor: "pointer" }}
          onClick={(e) => {
            props.closeModalToggle(e);
          }}
        >
          <ImCross size={"28px"} color="white" fill="white" />
        </p>
        <div className="modal-body">{props.children}</div>
      </div>
    </React.Fragment>
  );
};

export default Modal;
