import React from "react";
import "../styles/FloatingSocialMedia.css";

// Icons
import { AiFillInstagram, AiOutlineMail } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
const FloatingSocialMedia = () => {
  return (
    <div className="floating-media">
      <a href="mailto:contact@colorkaar.com" target="_blank" rel="noreferrer">
        <AiOutlineMail />
      </a>
      <a
        href="https://www.instagram.com/colorkaar/"
        target="_blank"
        rel="noreferrer"
      >
        <AiFillInstagram />
      </a>
      <a href="https://wa.me/+918872400624" target="_blank" rel="noreferrer">
        <BsWhatsapp />
      </a>
    </div>
  );
};

export default FloatingSocialMedia;
