import React, { useEffect, useState } from "react";
import useFirestore from "../hooks/useFirestore";
import "../styles/Work.css";
import { Helmet } from "react-helmet";
import category from "../assets/js/category.js";

//Components
import RandomPhotoGrid from "../components/RandomPhotoGrid";
import Spinner from "../components/Spinner";
import GalleryFilter from "../components/GalleryFilter";

const Work = () => {
  const [document, setDocument] = useState([]);
  const { docs } = useFirestore("images");
  const [isLoading, setIsLoading] = useState(false);
  const [json, setJson] = useState(category);
  useEffect(() => {
    var data = sessionStorage.getItem("data");
    if (data != null) {
      const parse = JSON.parse(data);
      setDocument([]);
      parse.forEach((doc) => {
        if (doc.homePage === true && doc.workPage === true) {
          if (doc?.id !== "DeEZIeulpqD7UghQIKIY") {
            setDocument((prevState) => [...prevState, doc]);
          }
        }
      });
      parse.forEach((doc) => {
        if (doc.homePage === false && doc.workPage === true) {
          setDocument((prevState) => [...prevState, doc]);
        }
      });
      setDocument((prevState) => [
        ...prevState,
        parse.filter((item) => item?.id === "DeEZIeulpqD7UghQIKIY")[0],
      ]);
    } else {
      setIsLoading(true);
      docs.sort((a, b) => {
        return a.position - b.position;
      });
      // docs.sort(compare);
      setDocument([]);
      // setTimeout(() => {
      docs.forEach((doc) => {
        if (doc.homePage === true && doc.workPage === true) {
          if (doc?.id !== "DeEZIeulpqD7UghQIKIY") {
            setDocument((prevState) => [...prevState, doc]);
          }
        }
      });
      docs.forEach((doc) => {
        if (doc.workPage === true && doc.homePage === false) {
          setDocument((prevState) => [...prevState, doc]);
        }
      });

      setDocument((prevState) => [
        ...prevState,
        docs.filter((item) => item?.id === "DeEZIeulpqD7UghQIKIY")[0],
      ]);

      // }, 1000);
      if (docs.length > 0) {
        setTimeout(() => {
          const stringify = JSON.stringify(docs);
          sessionStorage.setItem("data", stringify);

          setIsLoading(false);
        }, 2000);
      }
    }
  }, [docs]);

  return (
    <div className="work-main">
      <Helmet>
        <title>Colorkaar | Work</title>
        <meta
          name="description"
          content="Colorkaar - Color Grading Boutique 
Commercials , Films and Webseries Color Grading Services"
        />
      </Helmet>
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <GalleryFilter setJson={setJson} json={json} />
          <RandomPhotoGrid
            page={"Work"}
            docs={document}
            catergory={json.filter((f) => f.isActive === 1)[0]}
          />
        </>
      )}
    </div>
  );
};

export default Work;
