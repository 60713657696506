import React from "react";
import "../styles/Contact.css";
// Icons
import {
  // AiFillInstagram,
  AiOutlineYoutube,
  AiOutlineTwitter,
  AiOutlineMail,
} from "react-icons/ai";
import { FaLinkedinIn,FaInstagram } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";

const Social = ({ linkedin, instagram, whatapp, youtube, twitter, email }) => {
  //Coloured
  // const linkedinBg = {
  //   backgroundColor: "#0077b5",
  // };
  // const instagramBg = {
  //   background: "radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)",
  // };
  // const whatappBg = {
  //   backgroundColor: "#128C7E"
  // }
  // const youtubeBg = {
  //   backgroundColor: "#FF0000"
  // }
  // const twitterBg = {
  //   backgroundColor: "#1DA1F2"
  // }
  // const snapchatBg ={
  //   backgroundColor: "#FFFC00",
  // }

  //White
  const linkedinBg = {
    backgroundColor: "#000000",
    cursor: "pointer",
  };
  const instagramBg = {
    background: "#000000",
    cursor: "pointer",
  };
  const whatappBg = {
    backgroundColor: "#000000",
    cursor: "pointer",
  };
  const youtubeBg = {
    backgroundColor: "#000000",
    cursor: "pointer",
  };
  const twitterBg = {
    backgroundColor: "#000000",
    cursor: "pointer",
  };
  // const snapchatBg ={
  //   backgroundColor: "#000000",
  //   cursor : "pointer"
  // }
  const emailBg = {
    backgroundColor: "#000000",
    cursor: "pointer",
  };
  return (
    <div className="contact-socialmedia">
      {email && (
        <div className="contact-socialmedia-card" style={emailBg}>
          <a
            href="mailto:contact@colorkaar.com"
            target="_blank"
            rel="noreferrer"
          >
            <AiOutlineMail />
          </a>
        </div>
      )}
      {linkedin && (
        <div className="contact-socialmedia-card" style={linkedinBg}>
          <a
            href="https://www.linkedin.com/in/colorkaar/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedinIn />
          </a>
        </div>
      )}
      {instagram && (
        <div className="contact-socialmedia-card" style={instagramBg}>
          <a
            href="https://www.instagram.com/colorkaar/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram />
          </a>
        </div>
      )}
      {whatapp && (
        <div className="contact-socialmedia-card" style={whatappBg}>
          <a
            href="https://wa.me/+918872400624"
            target="_blank"
            rel="noreferrer"
          >
            <BsWhatsapp />
          </a>
        </div>
      )}
      {youtube && (
        <div className="contact-socialmedia-card" style={youtubeBg}>
          <a
            href="https://www.youtube.com/channel/UC535cMlzhgpNOk7xvgtGo6A"
            target="_blank"
            rel="noreferrer"
          >
            <AiOutlineYoutube />
          </a>
        </div>
      )}
      
      {twitter && (
        <div className="contact-socialmedia-card" style={twitterBg}>
          <a href="/about" target="_blank" rel="noreferrer">
            <AiOutlineTwitter />
          </a>
        </div>
      )}
    </div>
  );
};

export default Social;
