// import ReactGA from "react-ga";
import { GA4React } from "ga-4-react";

const ga4react = new GA4React("G-8JCH7EXC81").initialize();

const trackPathForAnalytics = (path, search, title) => {
  ga4react
    .then((ga) => {
      ga.pageview(path, search, title);
    })
    .catch((err) => console.error(`Analytics failed: ${err}`));
};
const useAnalyticsEventTracker = (category = "") => {
  // const eventTracker = (action = "", label = "") => {
  //   ReactGA.event({ category, action, label });
  // };
  // return eventTracker;
};
export { useAnalyticsEventTracker, trackPathForAnalytics };
