import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { PrivateRoute } from "./PrivateRoute";

//Components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

//Pages
import Home from "./pages/Home";
import Work from "./pages/Work";
import Reel from "./pages/Reel";
import About from "./pages/About";
import Contact from "./pages/Contact";
import WorkDetail from "./pages/WorkDetail";
import Login from "./pages/Login";
//import Admin from "./pages/Admin.js";
import PageNotFound from "./pages/PageNotFound";
import Adminv2 from "./pages/Adminv2";

//Context
import { AuthProvider } from "./context/AuthContext";

//Google Analytics
// import ReactGA from "react-ga";
// const TRACKING_ID = "UA-236599078-1";
// const TRACKING_ID = "G-8JCH7EXC81";

// ReactGA.initialize(TRACKING_ID);

function App() {
  const [loading, setLoading] = useState(true);
  function demoAsyncCall() {
    return new Promise((resolve) => setTimeout(() => resolve(), 500));
  }

  useEffect(() => {
    demoAsyncCall().then(() => setLoading(false));
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    // ReactGA.pageview(window.location.pathname + window.location.search);
    sessionStorage.clear();
  }, []);

  if (loading) {
    return null;
  }

  return (
    <div>
      <AuthProvider>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="*" element={<PageNotFound />} />
            <Route path="/" element={<Home />} />
            <Route path="/work/:id" element={<WorkDetail />} />
            <Route path="/about" element={<About />} />
            <Route path="/work" element={<Work />} />
            <Route path="/reel" element={<Reel />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/admin"
              element={
                <PrivateRoute>
                  <Adminv2 />
                </PrivateRoute>
              }
            />
          </Routes>
          <Footer />
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
