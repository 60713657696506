import React, { useEffect, useState } from "react";
import "../styles/SimplePhotoGrid.css";

export const SimplePhotoGrid = ({ multiplePhotoString, name }) => {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const arr =
      typeof multiplePhotoString === "string"
        ? multiplePhotoString.split(",")
        : [];

    arr.forEach((element) => {
      setPhotos((prevEle) => [...prevEle, element]);
    });
  }, [multiplePhotoString]);

  return (
    <div className="simple-photo-grid-wrapper">
      {photos.map((photo, k) => {
        return (
          <div className="simple-photo-wide" key={k}>
            <img src={photo} alt={name} loading=""/>
          </div>
        );
      })}
    </div>
  );
};

export default SimplePhotoGrid;
