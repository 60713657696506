import React, { useState } from "react";
import "../styles/GalleryFilter.css";
import { FaFilter } from "react-icons/fa";
const GalleryFilter = (props) => {
  const [toggleCategory, setToggleCategory] = useState(false);
  const handleCategory = (selected) => {
    props.json.map((item) => (item.isActive = 0));
    props.json.filter((f) => f.id === selected.id)[0].isActive = 1;
    props.setJson((curr) => [...curr]);
  };
  return (
    <>
      <div className="galleryfilter-mail">
        <ul>
          {props.json.map((category) => (
            <li
              style={{
                textDecoration: category.isActive ? "underline" : "none",
              }}
              key={category.id}
              onClick={() => handleCategory(category)}
            >
              {category.name}
            </li>
          ))}
        </ul>
      </div>
      <div className="galleryfilter-mail-mobile">
        {/* <div>{props.json.filter((f) => f.isActive === 1)[0].name}</div> */}
        <div
          className="floating-filter"
          onClick={() => setToggleCategory(!toggleCategory)}
        >
          <FaFilter size={"20px"} />
        </div>
        {toggleCategory && (
          <ul>
            {props.json.map((category) => (
              <li
                style={{
                  textDecoration: category.isActive ? "underline" : "none",
                }}
                key={category.id}
                onClick={() => {
                  handleCategory(category);
                  setToggleCategory(!toggleCategory);
                }}
              >
                {category.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default GalleryFilter;
