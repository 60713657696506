import React from "react";
import { Link } from "react-router-dom";
import "../styles/PageNotFound.css";

const PageNotFound = () => {
  return (
    <div className="pagenotfound-main">
      <p className="not-found"> page not found </p>
      <div className="tipsiz">
        <div className="tipsiz-body">
          <div className="left-arm arm"></div>
          <div className="face">
            <div className="upper-face">
              <div className="element">4</div>
              <div className="element">0</div>
              <div className="element">4</div>
            </div>
            <div className="mouth"></div>
          </div>
          <div className="right-arm arm"></div>
        </div>
      </div>
      <p> maybe you want to go back?</p> <Link to="/">Home</Link>
    </div>
  );
};

export default PageNotFound;
