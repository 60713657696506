import React, { useEffect, useState, useCallback } from "react";
import useFirestore from "../hooks/useFirestore";
import { useParams } from "react-router-dom";
import "../styles/WorkDetail.css";
//Components
import Heading from "../components/Heading";
import VideoSection from "../components/VideoSection";
import SimplePhotoGrid from "../components/SimplePhotoGrid";
import Spinner from "../components/Spinner";

const WorkDetail = () => {
  const { docs } = useFirestore("images");
  const [data, setData] = useState({});
  const { id } = useParams();
  const [videoLink, setVideoLink] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getData = useCallback(() => {
    setTimeout(() => {
      docs.forEach((doc) => {
        if (doc.id === id) {
          setData(doc);
          // const arr = typeof doc.videoLink === "string" ? doc.videoLink.split("/") : [];
          // const videoEmbedLink = "https://www.youtube.com/embed/" + arr[3] + "?rel=0";
          setVideoLink(doc.videoLink);
        }
      });
    }, 1400);
  }, [docs, id]);

  useEffect(() => {
    setIsLoading(true);

    let mounted = true;
    if (mounted) {
      getData();
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    window.scrollTo(0, 0);
    return function cleanup() {
      mounted = false;
    };
  }, [getData]);

  return (
    <div className="workdetail-main">
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          {" "}
          <VideoSection
            mheight={"50vh"}
            mwidth={"90vw"}
            height={"70vh"}
            width={"100vw"}
            videoLink={videoLink}
            name={data?.name}
          />
          <Heading heading={data.name} />
          {/* <Heading heading={"PHOTO GRID"} /> */}
          <SimplePhotoGrid
            multiplePhotoString={data.multiplePhoto}
            name={data.name}
          />
        </>
      )}
    </div>
  );
};

export default WorkDetail;
