import React from "react";
import "../styles/Reel.css";
import { Helmet } from "react-helmet";
//Components
import VideoSection from "../components/VideoSection";

const Reel = () => {
  return (
    <div className="reel-main">
      <Helmet>
        <title>Colorkaar | Reel</title>
        <meta
          name="description"
          content="Colorkaar - Color Grading Boutique Commercials , Films and Webseries Color Grading Services"
        />
      </Helmet>
      {/* <VideoSection videoLink={"https://www.youtube.com/embed/Og5blcCmBkE"} /> */}
      {/* <VideoSection videoLink={"https://vimeo.com/682360799"} /> */}
      {/* added date - 10Sep2022 */}
      {/* <VideoSection videoLink={"https://youtu.be/Og5blcCmBkE"} /> */}
      {/* added date - 31Jan2023 */}
      {/* <VideoSection videoLink={"https://vimeo.com/794436435"} /> */}
      {/* added date - 10Feb2023 */}
      <VideoSection
        mheight={"50vh"}
        mwidth={"90vw"}
        height={"70vh"}
        width={"100vw"}
        videoLink={"https://vimeo.com/796884551"}
        name={"colorkaar-reel"}
      />
    </div>
  );
};

export default Reel;
