import React, { useEffect, useState } from "react";
import "../styles/Home.css";
import useFirestore from "../hooks/useFirestore";
import { Helmet } from "react-helmet";
//Images
//01-09-2022
// import Poster from "../assets/img/videoplayback2.webp";
// import PosterMobile from "../assets/img/videoplaybackMobile2.webp";

//20-10-2022
import Poster from "../assets/img/desktopposteroct.jpg";
import PosterMobile from "../assets/img/mobileposteroct.jpg";

//Component
import Clients from "../components/Clients2";
import DownArrow from "../components/DownArrow";
import RandomPhotoGrid from "../components/RandomPhotoGrid";
// import Spinner from "../components/Spinner";
import { Dots } from "../components/Dots";
import { Link } from "react-router-dom";
import FloatingSocialMedia from "../components/FloatingSocialMedia";
import category from "../assets/js/category";

const Home = () => {
  const [document, setDocument] = useState([]);
  const { docs } = useFirestore("images");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    var data = sessionStorage.getItem("data");
    if (data != null) {
      const parse = JSON.parse(data);
      setDocument([]);
      parse.forEach((doc) => {
        if (doc.homePage === true && doc.workPage === true) {
          if (doc?.id !== "DeEZIeulpqD7UghQIKIY") {
            setDocument((prevState) => [...prevState, doc]);
          }
        }
      });
      parse.forEach((doc) => {
        if (doc.homePage === true && doc.workPage === false) {
          setDocument((prevState) => [...prevState, doc]);
        }
      });
      setDocument((prevState) => [
        ...prevState,
        parse.filter((item) => item?.id === "DeEZIeulpqD7UghQIKIY")[0],
      ]);
    } else {
      setIsLoading(true);
      docs.sort((a, b) => {
        return a.position - b.position;
      });
      // docs.sort(compare);
      setDocument([]);
      // setTimeout(() => {
      docs.forEach((doc) => {
        if (doc.homePage === true && doc.workPage === true) {
          if (doc?.id !== "DeEZIeulpqD7UghQIKIY") {
            setDocument((prevState) => [...prevState, doc]);
          }
        }
      });
      docs.forEach((doc) => {
        if (doc.workPage === false && doc.homePage === true) {
          setDocument((prevState) => [...prevState, doc]);
        }
      });
      setDocument((prevState) => [
        ...prevState,
        docs.filter((item) => item?.id === "DeEZIeulpqD7UghQIKIY")[0],
      ]);

      // }, 1000);
      if (docs.length > 0) {
        setTimeout(() => {
          const stringify = JSON.stringify(docs);
          sessionStorage.setItem("data", stringify);

          setIsLoading(false);
        }, 2000);
      }
    }
  }, [docs]);

  //01-09-2022
  // const videoMobile =
  //   "https://firebasestorage.googleapis.com/v0/b/colorkaar-2ddd8.appspot.com/o/BannerVideo%2Fwebsite_vertical.mp4%20(1080p)%20(1).mp4?alt=media&token=29d5c558-fe76-413d-a9ec-6cefd854bc33";
  // const videoDesktop =
  //   "https://firebasestorage.googleapis.com/v0/b/colorkaar-2ddd8.appspot.com/o/BannerVideo%2Fwebsite_video.mp4%20(1080p).mp4?alt=media&token=31c673df-65c7-409e-b936-a7d3a2612002";

  //20-10-2022
  const videoMobile =
    "https://firebasestorage.googleapis.com/v0/b/colorkaar-2ddd8.appspot.com/o/BannerVideo%2Fmobilebanneroct.mp4?alt=media&token=5003343f-e44a-42de-9a16-cd1457b6f198";
  const videoDesktop =
    "https://firebasestorage.googleapis.com/v0/b/colorkaar-2ddd8.appspot.com/o/BannerVideo%2Fdesktopbanneroct.mp4?alt=media&token=727c4c96-fef3-4ff8-b06f-a9642eeeafbf";

  return (
    <div>
      <Helmet>
        <title>Colorkaar | Home</title>
        <meta
          name="description"
          content="Colorkaar - Color Grading Boutique Commercials , Films and Webseries Color Grading Services"
        />
        <meta
          name="image"
          property="og:image"
          content="https://www.colorkaar.com/aboutbanner.webp"
        />
      </Helmet>
      <div className="home-videbanner videoBG-mobile-div">
        <video
          id="videoBG-mobile"
          poster={PosterMobile}
          autoPlay
          muted
          loop
          playsInline
        >
          <source src={videoMobile} type="video/mp4" />
        </video>
      </div>

      <div className="home-videbanner videoBG-div">
        <video id="videoBG" poster={Poster} autoPlay muted loop playsInline>
          <source src={videoDesktop} type="video/mp4" />
        </video>
      </div>
      <FloatingSocialMedia />
      <DownArrow />

      <div className="home-content">
        {isLoading ? (
          ""
        ) : (
          <>
            {/* <RandomPhotoGrid docs={document} /> */}
            <RandomPhotoGrid
              page={"Home"}
              docs={document}
              catergory={category.slice(0, 1)}
            />
            <Link to="/work">
              <Dots />
            </Link>
          </>
        )}
        <div className="about-main-desktop-contact">SOME OF OUR CLIENTS</div>

        <Clients />
      </div>
    </div>
  );
};

export default Home;
