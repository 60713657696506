import React, { useState, useRef, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import List from "../components/List";
import { useAuth } from "../context/AuthContext";
import Spinner from "../components/Spinner";
import {
  projectFirestore,
  projectStorage,
  timestamp,
} from "../firebase/config";
import "../styles/Admin.css";
import useFirestore from "../hooks/useFirestore";

import { CgWebsite } from "react-icons/cg";
import { BiCategory } from "react-icons/bi";

import { MdPhotoSizeSelectLarge } from "react-icons/md";
import Heading from "../components/Heading";
import category from "../assets/js/category";
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result[endIndex].position = endIndex;
  result[startIndex].position = startIndex;
  const min = Math.min(startIndex, endIndex);
  const max = Math.max(startIndex, endIndex);
  let iterator = min;
  for (let r = min; r <= max; r++) {
    result[r].position = iterator++;
  }
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  borderRadius: "5px",
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "lightgreen" : "var(--text)",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "var(--bg)",
  padding: grid,
  width: "100%",
});
const Adminv2 = () => {
  const { docs } = useFirestore("images");
  const [error, setError] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  const [showUpdateForm, setShowUpdateForm] = useState(false);

  const [noteForm, setNoteForm] = useState(false);
  const [document, setDocument] = useState([]);
  const [documentHome, setDocumentHome] = useState([]);
  const [documentWork, setDocumentWork] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const nameRef = useRef("");
  // const positionRef = useRef("");
  const videoLinkRef = useRef("");
  const [videoLinkEditRef, setVideoLinkEditRef] = useState("");
  const [nameEditRef, setNameEditRef] = useState("");
  const [idEditRef, setIdEditRef] = useState("");
  //const [positionEditRef, setPositionEditRef] = useState(0);

  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [toggleClassHome, setToggleClassHome] = useState(false);
  const [toggleClassWork, setToggleClassWork] = useState(false);
  const [toggleSizeSmall, setToggleSizeSmall] = useState(false);
  const [toggleSizeMedium, setToggleSizeMedium] = useState(false);
  const [toggleSizeLarge, setToggleSizeLarge] = useState(false);
  const [coverfile, setCoverFile] = useState(null);
  const [multiplefile, setMultipleFile] = useState([]);
  const [classBorderGreen, setClassBorderGreen] = useState(false);
  const [classBorderGreenMultiple, setClassBorderGreenMultiple] =
    useState(false);
  const [json, setJson] = useState(category);

  const types = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
  useEffect(() => {
    setIsLoading(true);
    setShowUpdateForm(false);
    docs.sort((a, b) => {
      return a.position - b.position;
    });

    setTimeout(() => {
      setDocument([]);
      setDocumentHome([]);
      setDocumentWork([]);
      docs.forEach((doc) => {
        if (doc.workPage === true && doc.homePage === false) {
          setDocumentWork((prevState) => [...prevState, doc]);
        } else if (doc.homePage === true && doc.workPage === false) {
          setDocumentHome((prevState) => [...prevState, doc]);
        } else {
          if (doc.id !== "DeEZIeulpqD7UghQIKIY") {
            setDocument((prevState) => [...prevState, doc]);
          }
        }
      });
      setTimeout(() => {
        // window.document.getElementById("admin-form-update-work").style.display =
        //   "none";
        // window.document.getElementById("admin-form-update-home").style.display =
        //   "none";
        // window.document.getElementById(
        //   "admin-form-update-homework"
        // ).style.display = "none";

        setIsLoading(false);
      }, 1000);
    }, 2500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docs]);
  async function handleLogout() {
    try {
      setError("");
      await logout();
      navigate("/login");
    } catch {
      setError("Failed to log out");
    }
  }
  const updateFormWithPosition = (e) => {
    e.preventDefault();
    let submitter = e.nativeEvent.submitter.name;
    const collectionRef = projectFirestore.collection("images");
    let loopfrom = null;

    if (submitter === "update-home") {
      loopfrom = documentHome;
    } else if (submitter === "update-work") {
      loopfrom = documentWork;
    } else if (submitter === "update-homework") {
      loopfrom = document;
    }
    let countnum = 1;
    for (let lf in loopfrom) {
      const data = {
        position: loopfrom[lf].position,
      };
      collectionRef
        .doc(loopfrom[lf].id)
        .update(data)
        .catch((err) => {
          console.log("Updating Position - ", err);
        });

      if (countnum === loopfrom.length) {
        setShowUpdateForm(false);
        if (submitter === "update-home") {
          window.document.getElementById(
            "admin-form-update-home"
          ).style.display = "none";
        } else if (submitter === "update-work") {
          window.document.getElementById(
            "admin-form-update-work"
          ).style.display = "none";
        } else if (submitter === "update-homework") {
          window.document.getElementById(
            "admin-form-update-homework"
          ).style.display = "none";
        }
      }
      countnum++;
    }
  };
  const updateForm = (e) => {
    e.preventDefault();
    const categories = json.filter((f) => f.isActive === 1);

    const data = {
      name: e.target[0].value,
      videoLink: e.target[1].value,
      homePage: toggleClassHome,
      workPage: toggleClassWork,
      updatedAt: timestamp(),
      category: categories,
    };
    // const data = {
    //   position: e.target[0].value,
    //   name: e.target[1].value,
    //   videoLink: e.target[2].value,
    //   updatedAt: timestamp(),
    //   homePage: toggleClassHome,
    //   workPage: toggleClassWork,
    // };
    const collectionRef = projectFirestore.collection("images");

    collectionRef
      .doc(idEditRef)
      .update(data)
      .then((res) => {
        setShowEditForm(false);
        setVideoLinkEditRef("");
        setNameEditRef("");
        setIdEditRef("");
        //setPositionEditRef("");
        setToggleClassWork(false);
        setToggleClassHome(false);
        inActiveAllJsonValue();
      })
      .catch((err) => {
        setError("Failed to update");
      });
  };

  const handleEdit = (id) => {
    let data = docs.find((element) => element.id === id);

    setShowEditForm(true);
    setVideoLinkEditRef(data.videoLink);
    setNameEditRef(data.name);
    setIdEditRef(id);
    data.workPage ? setToggleClassWork(true) : setToggleClassWork(false);
    data.homePage ? setToggleClassHome(true) : setToggleClassHome(false);
    handleCategory(data.category[0]);
    //setPositionEditRef(data.position);
  };
  async function handleFirestore(file) {
    const d = new Date();
    let month = d.getMonth() + 1;
    let date = d.getDate();
    let year = d.getFullYear();
    let time = d.getTime();
    const storageRef = projectStorage.ref();
    const fileRef = storageRef.child(
      month + "_" + date + "_" + year + "_" + time
    );

    await fileRef.put(file);
    let downloadUrl = fileRef.getDownloadURL();

    return downloadUrl;
  }
  const handleEditNameField = (e) => {
    const text = e.target.value;

    setNameEditRef((prev) => text);
  };

  // const handleEditPositionField = (e) => {
  //   const num = e.target.value;

  //   setPositionEditRef((prev) => num);
  // };
  const handleEditVideoLinkField = (e) => {
    const vlink = e.target.value;
    setVideoLinkEditRef((prev) => vlink);
  };

  const handleMultiplePhotoChange = (e) => {
    setClassBorderGreenMultiple(false);
    setLoading(true);

    let interval = 2500;

    Array.from(e.target.files).forEach(function (file, index) {
      setTimeout(() => {
        if (file && types.includes(file.type)) {
          handleFirestore(file)
            .then((res) => {
              setError("");
              setProgress((curr) => curr + 1);
              if (index === e.target.files.length - 1) {
                setClassBorderGreenMultiple(true);
                setLoading(false);
              }
              setMultipleFile((prevState) => [...prevState, res]);
            })
            .catch((err) => {
              setMultipleFile([]);
              setError("Unable to insert multiple photo to storage");
            });
        } else {
          setMultipleFile([]);
          setError("Please select an image file (png or jpg)");
        }
      }, index * interval);
    });
  };

  const handleCoverPhotoChange = (e) => {
    let selected = e.target.files[0];
    let interval = 2000;
    setLoading(true);

    setTimeout(() => {
      if (selected && types.includes(selected.type)) {
        handleFirestore(selected)
          .then((res) => {
            setCoverFile(res);
            setError("");
            setClassBorderGreen(true);
            setLoading(false);
          })
          .catch((err) => {
            setCoverFile(null);

            setError("Unable to insert photo to storage");
          });
      } else {
        setCoverFile(null);
        setError("Please select an image file (png or jpg)");
      }
    }, interval);
  };

  function handleSubmit(e) {
    e.preventDefault();
    const blackIMG =
      "https://firebasestorage.googleapis.com/v0/b/colorkaar-2ddd8.appspot.com/o/2_5_2023_1675613309851?alt=media&token=bc2f53c5-3cd6-45a2-81d8-c8c0e2507e00";
    console.log(multiplefile);
    let flag = false;
    let imageSize = "fit";
    if (
      toggleSizeSmall === true &&
      toggleSizeMedium === true &&
      toggleSizeLarge === true
    ) {
      flag = true;
    } else if (
      (toggleSizeSmall === true && toggleSizeMedium === true) ||
      (toggleSizeMedium === true && toggleSizeLarge === true) ||
      (toggleSizeLarge === true && toggleSizeSmall === true) ||
      (toggleClassHome === false && toggleClassWork === false)
    ) {
      flag = true;
    } else {
      flag = false;
      if (toggleSizeSmall === true) {
        imageSize = "fit";
      } else if (toggleSizeMedium === true) {
        imageSize = "horizontal";
      } else if (toggleSizeLarge === true) {
        imageSize = "big";
      }
    }
    const categories = json.filter((f) => f.isActive === 1);
    if (categories.length > 0) {
      flag = false;
    } else {
      flag = true;
    }
    if (flag === true) {
      setError("Failed to submit");
    } else {
      const data = {
        name: nameRef.current.value,
        position: 0,
        videoLink: videoLinkRef.current.value,
        coverPhoto: coverfile,
        multiplePhoto: blackIMG,
        homePage: toggleClassHome,
        workPage: toggleClassWork,
        imageSize: imageSize,
        createdAt: timestamp(),
        updatedAt: timestamp(),
        category: categories,
      };
      const collectionRef = projectFirestore.collection("images");

      collectionRef
        .add(data)
        .then((res) => {
          setShowForm(false);
          setClassBorderGreenMultiple(false);
          setClassBorderGreen(false);
          setToggleClassHome(false);
          setToggleClassWork(false);
          setToggleSizeSmall(false);
          setToggleSizeMedium(false);
          setToggleSizeLarge(false);
          inActiveAllJsonValue();
          setMultipleFile([]);
          setError("");
          setProgress(0);
        })
        .catch((err) => {
          setError("Failed to submit to storage");
        });
    }
  }
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    setShowUpdateForm(true);
    const reorderedItems = reorder(
      document,
      result.source.index,
      result.destination.index
    );

    setDocument(reorderedItems);
    setTimeout(() => {
      window.document.getElementById(
        "admin-form-update-homework"
      ).style.display = "flex";
    }, 1200);
  };
  const onDragEndHome = (result) => {
    if (!result.destination) {
      return;
    }
    setShowUpdateForm(true);
    const reorderedItems = reorder(
      documentHome,
      result.source.index,
      result.destination.index
    );

    setDocumentHome(reorderedItems);
    setTimeout(() => {
      window.document.getElementById("admin-form-update-home").style.display =
        "flex";
    }, 1200);
  };
  const onDragEndWork = (result) => {
    if (!result.destination) {
      return;
    }
    setShowUpdateForm(true);
    const reorderedItems = reorder(
      documentWork,
      result.source.index,
      result.destination.index
    );

    setDocumentWork(reorderedItems);
    setTimeout(() => {
      window.document.getElementById("admin-form-update-work").style.display =
        "flex";
    }, 1200);
  };

  async function updateInstagram() {
    const BASE_URL = 
    // "https://colorkaar-backend.onrender.com/api/"
    "https://myservicesbackendapi-production.up.railway.app/api/cgservices/";

    await fetch(BASE_URL + "instagram", {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
      },
      body: "",
    }).then((res) => {
      console.log("Instagram Updated!!");
    });
  }
  const inActiveAllJsonValue = () => {
    json.map((item) => (item.isActive = 0));
  };
  const handleCategory = (selected) => {
    inActiveAllJsonValue();
    json.filter((f) => f.id === selected.id)[0].isActive = 1;
    setJson((curr) => [...curr]);
  };
  return (
    <div className="admin-main">
      <div className="admin-buttons">
        <button onClick={handleLogout}>Log Out</button>
        <button
          type="button"
          onClick={() => {
            setShowForm(true);
            setNoteForm(false);
          }}
        >
          Add
        </button>
        <button
          type="button"
          onClick={() => {
            updateInstagram();
          }}
        >
          Update Instagram
        </button>
      </div>

      <div className="admin-main-floating">
        {showUpdateForm && (
          <div className="admin-form-div">
            <form className="admin-form" onSubmit={updateFormWithPosition}>
              <button
                type="submit"
                style={{ display: "none" }}
                name="update-home"
                id="admin-form-update-home"
              >
                Update Home
              </button>
              <br />
              <button
                type="submit"
                style={{ display: "none" }}
                name="update-work"
                id="admin-form-update-work"
              >
                Update Work
              </button>
              <br />
              <button
                type="submit"
                style={{ display: "none" }}
                name="update-homework"
                id="admin-form-update-homework"
              >
                Update Home & Work
              </button>
              {/* <button type="button" onClick={() => setShowUpdateForm(false)}>
                Close
              </button> */}
            </form>
          </div>
        )}
        {noteForm && (
          <div className="admin-form-div">
            <span className="admin-form-note">First Size - "L"</span>
            <span className="admin-form-note">Pattern to be followed:</span>
            <span className="admin-form-note">1. "M" - "M"</span>
            <span className="admin-form-note">
              2. "S" - "S" - "M" - "S" - "S" - "M"
            </span>
            <button
              type="button"
              onClick={() => setNoteForm(false) && setError("")}
            >
              Close
            </button>
          </div>
        )}
        {showEditForm && (
          <div className="admin-form-div">
            <form className="admin-form" onSubmit={updateForm}>
              {/* <label>Position</label>
            <input
              type="number"
              value={positionEditRef}
              onChange={handleEditPositionField}
              required
              placeholder="position"
            /> */}
              <label>Name</label>
              <input
                type="name"
                value={nameEditRef}
                onChange={handleEditNameField}
                required
                placeholder="name"
              />
              <label>Video Link</label>
              <input
                type="text"
                value={videoLinkEditRef}
                onChange={handleEditVideoLinkField}
                required
                placeholder="video link"
              />
              {/* <label>
            Grid Photos <span>{progress}</span>
          </label>
          <input
            type="file"
            name="multiplePhotos"
            accept="image/png, image/jpeg,, image/webp"
            multiple
            onChange={handleMultiplePhotoChange}
            className={
              classBorderGreenMultiple
                ? "admin-form-greenborder"
                : "admin-form-blackborder"
            }
          /> */}
              <div className="admin-form-checkbox">
                <label>
                  <CgWebsite />
                </label>
                <input
                  type="checkbox"
                  id="page1home"
                  name="page1home"
                  value="home"
                />
                <label
                  htmlFor="page1home"
                  className={
                    !toggleClassHome
                      ? "admin-form-check-simple"
                      : "admin-form-check-highlight"
                  }
                  onClick={() => setToggleClassHome(!toggleClassHome)}
                >
                  {" "}
                  Home
                </label>
                <input
                  type="checkbox"
                  id="page2work"
                  name="page2work"
                  value="work"
                />
                <label
                  htmlFor="page2work"
                  className={
                    !toggleClassWork
                      ? "admin-form-check-simple"
                      : "admin-form-check-highlight"
                  }
                  onClick={() => setToggleClassWork(!toggleClassWork)}
                >
                  {" "}
                  Work
                </label>
              </div>
              <div
                className="admin-form-checkbox"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <label>
                  <BiCategory />
                </label>
                {json.slice(1, 20).map((item) => (
                  <>
                    {" "}
                    <input
                      type="radio"
                      id={`page1editcategory${item.id}`}
                      name="page1editcategory"
                      value={item.name}
                    />
                    <label
                      htmlFor="page1editcategory"
                      className={
                        !json.filter((f) => f.id === item.id)[0].isActive
                          ? // !toggleClassHome
                            "admin-form-check-simple"
                          : "admin-form-check-highlight"
                      }
                      onClick={() => handleCategory(item)}
                    >
                      {item.name}
                    </label>
                  </>
                ))}
              </div>
              <button type="submit">Update</button>
              <button
                type="button"
                onClick={() => {
                  setShowEditForm(false);
                  setToggleClassHome(false);
                  setToggleClassWork(false);
                  inActiveAllJsonValue();
                }}
              >
                Close
              </button>
            </form>
          </div>
        )}
        {showForm && (
          <div className="admin-form-div">
            {error && <span className="admin-error">{error}</span>}
            <form className="admin-form" onSubmit={handleSubmit}>
              <label>Cover Photo</label>
              <input
                type="file"
                name="coverphoto"
                accept="image/png, image/jpeg, image/webp"
                onChange={handleCoverPhotoChange}
                className={
                  classBorderGreen
                    ? "admin-form-greenborder"
                    : "admin-form-blackborder"
                }
              />
              {coverfile && (
                <div className="admin-filename">{coverfile.name}</div>
              )}
              {/* <label>Position</label>
            <input
              type="number"
              ref={positionRef}
              required
              placeholder="position"
            /> */}
              <label>Name</label>
              <input type="name" ref={nameRef} required placeholder="name" />
              <label>Video Link</label>
              <input
                type="text"
                ref={videoLinkRef}
                required
                placeholder="video link"
              />

              <div className="admin-form-checkbox">
                <label>
                  <CgWebsite />
                </label>
                <input
                  type="checkbox"
                  id="page1home"
                  name="page1home"
                  value="home"
                />
                <label
                  htmlFor="page1home"
                  className={
                    !toggleClassHome
                      ? "admin-form-check-simple"
                      : "admin-form-check-highlight"
                  }
                  onClick={() => setToggleClassHome(!toggleClassHome)}
                >
                  {" "}
                  Home
                </label>
                <input
                  type="checkbox"
                  id="page2work"
                  name="page2work"
                  value="work"
                />
                <label
                  htmlFor="page2work"
                  className={
                    !toggleClassWork
                      ? "admin-form-check-simple"
                      : "admin-form-check-highlight"
                  }
                  onClick={() => setToggleClassWork(!toggleClassWork)}
                >
                  {" "}
                  Work
                </label>
              </div>
              <div
                className="admin-form-checkbox"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <label>
                  <BiCategory />
                </label>
                {json.slice(1, 20).map((item) => (
                  <>
                    {" "}
                    <input
                      type="radio"
                      id={`page1category${item.id}`}
                      name="page1category"
                      value={item.name}
                    />
                    <label
                      htmlFor="page1category"
                      className={
                        !json.filter((f) => f.id === item.id)[0].isActive
                          ? // !toggleClassHome
                            "admin-form-check-simple"
                          : "admin-form-check-highlight"
                      }
                      onClick={() => handleCategory(item)}
                    >
                      {item.name}
                    </label>
                  </>
                ))}
              </div>
              <div className="admin-form-checkbox">
                <label>
                  <MdPhotoSizeSelectLarge />
                </label>
                <input type="checkbox" id="size1" name="size1" value="small" />
                <label
                  htmlFor="size1"
                  className={
                    !toggleSizeSmall
                      ? "admin-form-check-simple"
                      : "admin-form-check-highlight"
                  }
                  onClick={() => setToggleSizeSmall(!toggleSizeSmall)}
                >
                  {" "}
                  S
                </label>
                <input type="checkbox" id="size2" name="size2" value="medium" />
                <label
                  htmlFor="size2"
                  className={
                    !toggleSizeMedium
                      ? "admin-form-check-simple"
                      : "admin-form-check-highlight"
                  }
                  onClick={() => setToggleSizeMedium(!toggleSizeMedium)}
                >
                  {" "}
                  M
                </label>
                <input type="checkbox" id="size3" name="size3" value="large" />
                <label
                  htmlFor="size3"
                  className={
                    !toggleSizeLarge
                      ? "admin-form-check-simple"
                      : "admin-form-check-highlight"
                  }
                  onClick={() => setToggleSizeLarge(!toggleSizeLarge)}
                >
                  {" "}
                  L
                </label>
              </div>
              <label style={{ display: "none" }}>
                Grid Photos <span>{progress}</span>
              </label>
              <input
                style={{ display: "none" }}
                type="file"
                name="multiplePhotos"
                accept="image/png, image/jpeg,, image/webp"
                multiple
                onChange={handleMultiplePhotoChange}
                className={
                  classBorderGreenMultiple
                    ? "admin-form-greenborder"
                    : "admin-form-blackborder"
                }
              />
              <button disabled={loading} type="submit">
                Submit
              </button>
              <button
                type="button"
                onClick={() => {
                  setShowForm(false);
                  setError("");
                  setToggleClassHome(false);
                  setToggleClassWork(false);
                  inActiveAllJsonValue();
                }}
              >
                Close
              </button>
            </form>{" "}
          </div>
        )}
      </div>

      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <div className="main_content">
            <DragDropContext onDragEnd={onDragEndWork}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    <Heading heading="Work" />
                    {documentWork &&
                      documentWork.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              className="card"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <List
                                position={item.position}
                                name={item.name}
                                link={item.videoLink}
                                homepage={item.homePage}
                                workpage={item.workPage}
                                multiplestring={item.multiplePhoto}
                                coverPhoto={item.coverPhoto}
                                id={item.id}
                                imageSize={item.imageSize}
                                handleEdit={handleEdit}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="main_content">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    <Heading heading="Home & Work" />
                    {document &&
                      document.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              className="card"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <List
                                position={item.position}
                                name={item.name}
                                link={item.videoLink}
                                homepage={item.homePage}
                                workpage={item.workPage}
                                multiplestring={item.multiplePhoto}
                                coverPhoto={item.coverPhoto}
                                id={item.id}
                                imageSize={item.imageSize}
                                handleEdit={handleEdit}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="main_content">
            <DragDropContext onDragEnd={onDragEndHome}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    <Heading heading="Home" />
                    {documentHome &&
                      documentHome.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              className="card"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <List
                                position={item.position}
                                name={item.name}
                                link={item.videoLink}
                                homepage={item.homePage}
                                workpage={item.workPage}
                                multiplestring={item.multiplePhoto}
                                coverPhoto={item.coverPhoto}
                                id={item.id}
                                imageSize={item.imageSize}
                                handleEdit={handleEdit}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </>
      )}
    </div>
  );
};

export default Adminv2;
