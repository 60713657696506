import React, { useRef, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import "../styles/Login.css";

// Icons
import { AiFillLock } from "react-icons/ai";

export default function Login() {
  const emailRef = useRef("");
  const passwordRef = useRef("");
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      navigate("/admin");
    } catch {
      setError("Failed to log in");
    }

    setLoading(false);
  }

  return (
    <>
      <div className="login">
        <div className="form">
          <form className="login-form" onSubmit={handleSubmit}>
            <span className="material-icons">
              <AiFillLock />
            </span>

            <input type="email" ref={emailRef} required placeholder="email" />
            <input
              type="password"
              ref={passwordRef}
              required
              placeholder="password"
            />
            <button disabled={loading} type="submit">
              login
            </button>
            {error && <span className="loginError">{error}</span>}
          </form>
        </div>
      </div>
    </>
  );
}
