import React from "react";
import "../styles/Footer.css";
// Images
import Logo from "../assets/img/ColorkaarLogo2.webp";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";

export const Footer = () => {
  return (
    <div className="footer-main">
      <div className="footer-left">
        <div className="footer-left-logo">
          <img src={Logo} alt="colorkaar-footer-logo" />
        </div>
        <div className="footer-left-text">
          {" "}
          &copy; 2023 Colorkaar.<br></br> Color Grading Boutique<br></br> All
          Right Reserved.{" "}
        </div>
      </div>

      <div className="footer-left-mobile">
        <div className="footer-left-mobile-text">
          {" "}
          &copy; 2024 Colorkaar. Color Grading Boutique<br></br> All Right
          Reserved.{" "}
        </div>
        <div className="footer-left-mobile-social">
          {" "}
          <ul>
            <li>
              <a
                href="mailto:contact@colorkaar.com"
                target="_blank"
                rel="noreferrer"
                style={{ color: "var(--text)" }}
              >
                <AiOutlineMail size={"15px"} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/colorkaar/"
                target="_blank"
                rel="noreferrer"
                style={{ color: "var(--text)" }}
              >
                <FaInstagram size={"15px"} />
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/+918872400624"
                target="_blank"
                rel="noreferrer"
                style={{ color: "var(--text)" }}
              >
                <FaWhatsapp size={"15px"} />
              </a>
            </li>
            <li style={{ margin: "0px 16px 4px 16px" }}>
              <a
                href="https://www.linkedin.com/in/chitvangarg/"
                target="_blank"
                rel="noreferrer"
                style={{ fontSize: "13px" }}
              >
                👨🏻‍💻
              </a>
            </li>
            <li style={{ margin: "0px 16px 4px 16px" }}>
              <a
                href="https://www.instagram.com/pranvmehta/"
                target="_blank"
                rel="noreferrer"
                style={{ fontSize: "13px" }}
              >
                👨🏻‍🎨
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="footer-right">
        <div className="footer-center">
          <ul>
            <li>
              <a
                href="mailto:contact@colorkaar.com"
                target="_blank"
                rel="noreferrer"
              >
                <AiOutlineMail size={"2rem"} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/colorkaar/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram size={"2rem"} />
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/+918872400624"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp size={"2rem"} />
              </a>
            </li>
            <li style={{ marginBottom: "8px" }}>
              <a
                href="https://www.linkedin.com/in/chitvangarg/"
                target="_blank"
                rel="noreferrer"
                style={{ fontSize: "2rem" }}
              >
                👨🏻‍💻
              </a>
            </li>
            <li style={{ marginBottom: "8px" }}>
              <a
                href="https://www.instagram.com/pranvmehta/"
                target="_blank"
                rel="noreferrer"
                style={{ fontSize: "2rem" }}
              >
                👨🏻‍🎨
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    // <div className="footer-main">
    //   <div className="footer-left">
    //     <div className="footer-left-logo">
    //       <img src={Logo} alt="colorkaar-footer-logo" />
    //     </div>
    //     <div className="footer-left-text">
    //       {" "}
    //       &copy; 2022 Colorkaar.<br></br> Color Grading Boutique<br></br> All Right Reserved.  <div className="footer-left-text-credits">
    //       Logo by{" "}
    //       <a
    //         href="https://www.instagram.com/pranvmehta/"
    //         target="_blank"
    //         rel="noreferrer"
    //       >
    //         pranvmehta
    //       </a>{" "}
    //        <br /> Website by{" "}
    //       <a
    //         href="https://www.instagram.com/chitvangarg14/"
    //         target="_blank"
    //         rel="noreferrer"
    //       >
    //         chitvangarg
    //       </a>
    //     </div>
    //     </div>
    //   </div>
    //   <div className="footer-center">
    //     <ul>
    //       <li>
    //         <a
    //           href="mailto:contact@colorkaar.com"
    //           target="_blank"
    //           rel="noreferrer"
    //         >
    //           <AiOutlineMail size={"18px"}/>
    //         </a>
    //       </li>
    //       <li>
    //         <a
    //           href="https://www.instagram.com/colorkaar/"
    //           target="_blank"
    //           rel="noreferrer"
    //         >
    //           <FaInstagram size={"18px"}/>
    //         </a>
    //       </li>
    //       <li>
    //         <a
    //           href="https://wa.me/+918872400624"
    //           target="_blank"
    //           rel="noreferrer"
    //         >
    //           <FaWhatsapp size={"18px"}/>
    //         </a>
    //       </li>
    //     </ul>
    //   </div>
    //   <div className="footer-right">
    //     <div>
    //       Logo by{" "}
    //       <a
    //         href="https://www.instagram.com/pranvmehta/"
    //         target="_blank"
    //         rel="noreferrer"
    //       >
    //         pranvmehta
    //       </a>{" "}
    //     </div>
    //     <div>
    //       Website by{" "}
    //       <a
    //         href="https://www.instagram.com/chitvangarg14/"
    //         target="_blank"
    //         rel="noreferrer"
    //       >
    //         chitvangarg
    //       </a>
    //     </div>
    //   </div>
    //  <div className="footer-left">
    //     Logo by{" "}
    //     <a
    //       href="https://www.instagram.com/pranvmehta/"
    //       target="_blank"
    //       rel="noreferrer"
    //     >
    //       pranvmehta
    //     </a>{" "}
    //     & Website by{" "}
    //     <a
    //       href="https://www.instagram.com/chitvangarg14/"
    //       target="_blank"
    //       rel="noreferrer"
    //     >
    //       chitvangarg
    //     </a>
    //   </div>
    //   <div className="footer-center">
    //     Copyright &copy; 2022 Colorkaar. All Right Reserved.
    //   </div>
    // </div>
  );
};

export default Footer;
