import React, { useState, useEffect } from "react";
import "../styles/VideoSection.css";

const VideoSection = ({ videoLink, width, height, mwidth, mheight, name }) => {
  const [vLink, setVideoLink] = useState("");
  const [mediaShowWebsite, setMediaShowWebsite] = useState(true);
  useEffect(() => {
    if (videoLink === null || videoLink === "" || videoLink === undefined) {
      setVideoLink("");
    } else {
      if (videoLink.indexOf("youtu") !== -1) {
        const arr = typeof videoLink === "string" ? videoLink.split("/") : [];
        const videoEmbedLink =
          "https://www.youtube.com/embed/" + arr[3] + "?rel=0&autoplay=1";
        setVideoLink(videoEmbedLink);
      } else if (videoLink.indexOf("vimeo") !== -1) {
        const vimeoArr =
          typeof videoLink === "string" ? videoLink.split("/") : [];
        const vimeoVideoEmbedLink =
          "https://player.vimeo.com/video/" + vimeoArr[3] + "?autoplay=1";
        setVideoLink(vimeoVideoEmbedLink);
      } else {
        setVideoLink("");
      }
      setMediaShowWebsite(window.matchMedia("(min-width: 998px)").matches);
    }
  }, [videoLink]);

  return (
    <>
      {" "}
      {mediaShowWebsite ? (
        <div className="videoSection-main" style={{ width, height }}>
          <iframe
            title="player"
            src={vLink}
            width="65%"
            height="100%"
            frameBorder="0"
            allowFullScreen
            id={name}
            allow="autoplay"
          ></iframe>
        </div>
      ) : (
        <div
          className="videoSection-main-mobile"
          style={{ width: mwidth, height: mheight, margin: 0 }}
        >
          <iframe
            id={name}
            title="player"
            src={vLink}
            width="100%"
            height="70%"
            frameBorder="0"
            allowFullScreen
            allow="autoplay"
          ></iframe>
        </div>
      )}
    </>
  );
};

export default VideoSection;
